@import "variables", "mixins";

//
// Headings
//

h1, .h1 { font-size: $font-size-h1-xs; }
h2, .h2 { font-size: $font-size-h2-xs; }
h3, .h3 { font-size: $font-size-h3-xs; }
h4, .h4 { font-size: $font-size-h4-xs; }
h5, .h5 { font-size: $font-size-h5-xs; }

@include media-breakpoint-up(md) {
    h1, .h1 { font-size: $font-size-h1-md; }
    h2, .h2 { font-size: $font-size-h2-md; }
    h3, .h3 { font-size: $font-size-h3-md; }
    h4, .h4 { font-size: $font-size-h4-md; }
    h5, .h5 { font-size: $font-size-h5-md; }
}

@include media-breakpoint-up(lg) {
    h1, .h1 { font-size: $font-size-h1-lg; }
    h2, .h2 { font-size: $font-size-h2-lg; }
    h3, .h3 { font-size: $font-size-h3-lg; }
    h4, .h4 { font-size: $font-size-h4-lg; }
    h5, .h5 { font-size: $font-size-h5-lg; }

    .ql-editor .ql-size-huge { font-size: $font-size-h4-lg; }
    .ql-editor .ql-size-large { font-size: $font-size-h5-lg; }
}

h3, h4,
.h3, .h4 {
    font-weight: $font-weight-normal;
}

.ql-editor .ql-size-large { font-weight: $font-weight-bold; }

.ql-editor .ql-size-normal { font-size: $font-size-base; }


//
// Emphasis
//

small,
.small {
    font-size: $font-size-xs;
}

.ql-editor .ql-size-small { font-size: $font-size-xxs; }


//
// Misc
//
.tight {
    margin: 0;
}

.tight10 {
    margin: 10px;
}

.label {
    color: $blue-light;
}

// Blockquotes
blockquote {
    padding: 0;
    color: $blockquote-color;
    font-style: $blockquote-font-style;
    font-size: $font-size-xs;
    border-left: 4px solid $gray-lighter;
    padding-left: 5px;

    &.blockquote {
        font-size: $blockquote-font-size-xs;
        font-weight: lighter;
        line-height: 1.33;
        border-left: none;

        p {
            margin-bottom: 1rem;

            &:before {
                content: '\201C';
            }

            &:after {
                content: '\201D';
            }
        }

        @include media-breakpoint-up(sm) {
            p {
                margin-bottom: 1.5rem;
            }
        }
    
        @include media-breakpoint-up(md) {
            font-size: $blockquote-font-size-md;
    
            p {
                margin-bottom: 2rem;
            }
        }
    
    }

}

.blockquote-footer {
    height: auto;
    font-size: 0.9375rem;
    font-style: normal;
    opacity: 0.6;

    &:before {
        content: '';
    }

    @include media-breakpoint-up(md) {
        font-size: 1.25rem;
        opacity: 0.8;
    }
}

// Preformatted Text
.ql-snow .ql-editor pre.ql-syntax, pre {
    background: transparent;
    color: inherit;
    border-left: solid 1px $gray-light;
    padding-left: 5px !important;
    border-radius: $border-radius;  
 }

.ql-align-right {
    text-align: right;
}
.ql-align-center {
    text-align: center;
}
.ql-align-justify {
    text-align: justify;
}


