@import "variables", "mixins";

// Wrapper and base class
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
    padding: $navbar-padding-y calc($grid-gutter-width-base / 2);
    transition: background-color .3s;

    @include media-breakpoint-up(md) {
        @media (min-height: $min-device-height) {
            padding: $navbar-padding-y $navbar-padding-x;
        }
    }
}


// Navbar hr - bottom border

.navbar-hr {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    border-top: solid 1px;
}


//
// Brand/project name
//

.navbar-brand {
    margin-right: 0;
    padding: 0;
    align-self: flex-start;
    line-height: $navbar-height-xs;

    > img {
        display: inline-block;
        margin-top: -5px;
        width: 72px;
        vertical-align: middle;
    }

    @include media-breakpoint-up(sm) {
        align-self: auto;

        @media (min-height: $min-device-height) {
            line-height: $navbar-height-sm;

            > img {
                width: 90px;
            }
        }
    }
}


// Navigation
//
// Custom navbar navigation built on the base `.nav` styles.

.navbar-nav {
    flex-direction: row;
    height: $navbar-height-xs;
    line-height: 40px;

    .nav-link {
        padding: 0;
        line-height: inherit;
        font-weight: $font-weight-semibold;
        font-size: $font-size-sm;
    }

    .icon-arrow {
        font-size: .8em;
        margin-left: 1.5em;

        &:before {
            vertical-align: middle;
        }
    }

    @include media-breakpoint-up(sm) {
        @media (min-height: $min-device-height) {
            height: $navbar-height-sm;
            line-height: $navbar-height-sm;
        }
    }

    @include media-breakpoint-up(md) {
        .nav-item + .nav-item {
            margin-left: 1.375rem;
        }
    }

    @include media-breakpoint-up(lg) {
        .nav-item + .nav-item {
            margin-left: 2rem;
        }
    }
}


// Responsive navbar
//
// Custom styles for responsive collapsing and toggling of navbar contents.
// Powered by the collapse Bootstrap JavaScript plugin.

// Button for toggling the navbar when in its collapsed state
.navbar-toggler {
    padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
    border: none;

    &:focus {
        outline: none;
    }
}

// Keep as a separate element so folks can easily override it with another icon
// or image file as needed.
.navbar-toggler-icon {
    width: 40px;
    height: $navbar-height-xs;
    stroke-width: 2px;

    path {
        // transform-origin: center;
        transition: transform .3s ease-out, opacity .3s;
    }

    path:nth-child(1) { transform-origin: 50% 8px; }
    path:nth-child(3) { transform-origin: 50% 24px; }

    .navbar-toggler:not(.collapsed) & {
        path:nth-child(1) { transform: translateY(8px) rotate(45deg); }
        path:nth-child(2) { opacity: 0; }
        path:nth-child(3) { transform: translateY(-8px) rotate(-45deg); }
    }
}

// Generate series of `.navbar-toggleable-*` responsive classes for configuring
// where your navbar collapses.
.navbar-toggleable {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $next: breakpoint-next($breakpoint, $grid-breakpoints);
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        &#{$infix} {
            @include media-breakpoint-down($breakpoint) {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                height: auto;

                .navbar-collapse {
                    width: 100%;
                    order: 1;

                    .navbar-nav {
                        flex-direction: column;
                        padding-bottom: .625rem;
                        height: auto;
                        line-height: 40px;
                    }

                    .nav-item + .nav-item {
                        margin-left: 0;
                    }
                }
            }

            @include media-breakpoint-up($next) {
                .nav-item-toggler {
                    display: none;
                }
            }
        }
    }
}


// Dark links against a light background
.navbar-light {
    background-color: #fff;

    .visible-dark,
    .visible-primary {
        display: none !important;
    }

    .navbar-toggler {
        color: $navbar-light-color;
    }

    .navbar-nav {
        .nav-link {
            color: $navbar-light-color;

            @include hover-focus {
                color: $navbar-light-hover-color;
            }
        }

        .open > .nav-link,
        .active > .nav-link,
        .nav-link.open,
        .nav-link.active {
            @include plain-hover-focus {
                color: $navbar-light-active-color;
            }
        }
    }

    .navbar-toggler {
        border-color: transparent;
    }

    .navbar-toggler-icon {
        stroke: $navbar-light-color;
    }

    .navbar-hr {
        border-color: fade-out(#cacaca, 0.7);
    }
}

// White links against a dark background
.navbar-dark {
    background-color: transparent;

    .visible-light,
    .visible-primary {
        display: none !important;
    }

    .navbar-toggler {
        color: $navbar-dark-color;
    }

    .navbar-nav {
        .nav-link {
            color: $navbar-dark-color;

            @include hover-focus {
                color: $navbar-dark-hover-color;
            }
        }

        .open > .nav-link,
        .active > .nav-link,
        .nav-link.open,
        .nav-link.active {
            @include plain-hover-focus {
                color: $navbar-dark-active-color;
            }
        }
    }

    .navbar-toggler {
        border-color: transparent;
    }

    .navbar-toggler-icon {
        stroke: $navbar-dark-color;
    }

    .navbar-hr {
        border-color: transparent;
    }
}

// Force light theme when toggling menu
.navbar-toggle {
    @extend .navbar-light;
}
