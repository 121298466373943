@import "variables", "mixins";

//
// Reset HTML, body, and more
//

body {
    font-size: $font-size-base;
}


//
// Links
//

a {
    transition: $link-transition;
    // color: $blue-dark;
}


//
// Images
//

img {
    max-width: 100%;
}
