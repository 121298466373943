@import "variables", "mixins";

html {
	overflow-x: hidden;

	// reduce base font size when printing
	@media print {
		font-size: 0.6rem;
	}
}

body {
	// Required for responsive tables (only mobile)
	overflow: hidden;

	// Reset on larger devices to allow sticky calendar headers
	@include media-breakpoint-up(md) {
		overflow: initial;
	}
}

body.has-side-menu {
	@include media-breakpoint-up(lg) {
		padding-left: $side-menu-width;
	}

	@media print {
		padding-left: 0;
	}
}

main {
	min-height: calc(100vh - 306px);

	@include media-breakpoint-up(sm) {
        min-height: calc(100vh - #{$navbar-height-sm});
	}
	
	@media print {
		min-height: 0;
	}
}

header + main {
	@include media-breakpoint-up(sm) {
		min-height: calc(100vh - #{$navbar-height-sm * 2});
	}

	@media print {
		min-height: 0;
	}
}

.side-menu-toggler + main {
	margin-top: (5rem - $block-spacing-xs);
	min-height: calc(100vh - 306px - #{5rem - $block-spacing-xs});

	@include media-breakpoint-up(sm) {
		margin-top: (5rem - $block-spacing-sm);
		min-height: calc(100vh - #{$navbar-height-sm} - #{5rem - $block-spacing-sm});
	}

	@include media-breakpoint-up(md) {
		margin-top: (5rem - $block-spacing-md);
		min-height: calc(100vh - #{$navbar-height-sm} - #{5rem - $block-spacing-md});
	}

	@media print {
		min-height: 0;
	}
}


button,
input,
optgroup,
select,
textarea {
	font-family: inherit;
}


.image-icon {
	width: 100%;
	max-width: 90px;
}
.image-large {
	width: 100%;
	max-width: 700px;
}
.image-medium {
	width: 100%;
	max-width: 400px;
}
.image-small {
	width: 100%;
	max-width: 200px;
}


.alert-inline {
    padding: 0.15rem 1rem;
    margin-bottom: 0;
}
  


.new-feature {
    font-weight: bold;
    background-color: $green-lighter;
    border: $gray-light solid 1px;
    border-radius: $border-radius;
    padding: 2px 5px;

    &.inline {
        margin-left: 8px;
    }

    &.dark {
        color: $gray-dark;
    }
}


.container-centered {
    text-align: center;
}



/* Application icons */
/* Application icons */

.icon-list {
    fill: $brand-primary;
    height: 30px;
    width: 30px;
    display: inline-block;
    margin: 0 1rem 0 .2rem;
    vertical-align: middle;
}


.icon-slideshow {
    fill: white;
    height: 19px;
    width: 19px;
    display: inline-block;
}

.icon-switch {
    fill: $brand-primary;
    height: 19px;
    width: 19px;
    display: inline-block;
}



// Screen readers

.sr-only {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}


