@import "variables", "mixins";

footer {


    @media print {
        display: none;
    }

    @include media-breakpoint-up(sm) {
        height: $navbar-height-sm;
    }
}

.pagebreak {
    page-break-after: always;
}


.vo-status {
    position: fixed;
    bottom: 0px;
    width: 100%;
    // display: none; // We don't need to see these messages
    background-color: $gray-lightest;
    color: black;
    padding: 10px 30px;
    border-top: 2px solid $blue;
}
