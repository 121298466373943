@import "variables";

.help {
    opacity: .7;
    width: 300px;
    margin-left: 20px;

    &.centre {
        margin: auto;
    }

    &.clearance {
        margin-bottom: 20px;
    }
}

.help-toggle {
    position: fixed;
    top: 10px;
    right: -3px;
    padding: 4px;
    background: transparent;
    border: 1px solid $gray-light;
    border-radius: $border-radius;
    box-shadow: none;
    cursor: pointer;
    opacity: .3;
    transition: opacity .2s;

    &:hover {
        opacity: 1;
    }

	@media print {
		display: none !important;
	}    
}

.help-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 300px;
    background-color: #fff;
    box-shadow: -4px 0 40px rgba(#000, .1);
    overflow-y: auto;
    transform: translateX(100%);
    opacity: 0;
    z-index: $zindex-fixed;
    transition: transform .3s ease-out, opacity .2s;

    &.show {
        transform: translateX(0%);
        opacity: 1;
    }

    h1 {
        margin-top: 24px;
        margin-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
        font-size: 1.5rem;
    }

    iframe {
        margin-bottom: 16px;
        width: 100%;
        height: 168px;
    }

    p {
        margin-bottom: 3px;
        padding-left: 16px;
        padding-right: 16px;
        font-size: .875rem;

    }

    p.tags {
        font-size: .6rem;
        margin-top: 30px;
        margin-bottom: 1px;
    }

    .help-close {
        display: block;
        margin: 12px;
        margin-left: auto;
        padding: 0;
        background: transparent;
        border: none;
        box-shadow: none;
        cursor: pointer;
        opacity: .5;
        transition: opacity .2s;
    
        &:hover {
            opacity: 1;
        }
    }
}



.pseudoTextArea {
    -moz-appearance: textfield-multiline;
    -webkit-appearance: textarea;
    // border: 1px solid gray;
    font: medium -moz-fixed;
    font: -webkit-small-control;
    height: 6rem;
    overflow: auto;
    // padding: 2px;
    resize: both;
    // width: 100%;
}




.welcome {
    font-size: 130%;
    padding-right: 30%;
}

