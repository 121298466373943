@import "variables";

.history-entry {
    margin: 10px 0 10px 0;
}

.onscreen-actions {
    margin: 10px 30px 10px 0;
    text-align: right;
}
